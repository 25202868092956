import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService, Senha } from "lib-smart-core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'links-component',
  templateUrl: './links.component.html'
})
export class LinksComponent extends AbstractComponent implements OnInit {

  model: any = {};

  senhaSelecionada: Senha;
  filaSelecionada: Fila;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];

  constructor(
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.loadFranquiaPorCodigo(codigo);
    }
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.model.urlFilas = environment.publicUrl + '/filas/' + this.franquia.codigo;
        this.model.urlFilasTablet = `${environment.publicUrl}/filas/${this.franquia.codigo}?panel=false`;
        this.model.urlFilasQRCode = environment.publicUrl + '/filas-qrcode/' + this.franquia.codigo;
        this.model.urlPainel = environment.publicUrl + '/painel/' + this.franquia.codigo;
        this.model.urlAvaliacao = environment.publicUrl + '/avaliacao/' + this.franquia.codigo;
        this.model.urlTablet = environment.publicUrl + '/tablet/' + this.franquia.codigo;
        this.model.urlTabletQRCode = environment.publicUrl + '/tablet-qrcode/' + this.franquia.codigo;
        this.loadConfig();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  goTo(url: string) {
    window.location.href = url;
  }

  copyCode(value: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
