
export const sessionFilasQRCode = "sessionFilasQRCode";
export const sessionSemPainel = "sessionSemPainel";

export interface AppSession {
  flow: FlowSessionEnum;
}

export enum FlowSessionEnum {
  QRCODE = 'QRCODE',
  MANUAL = "MANUAL"
}
