import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFilaService, PublicFranquiaService, PublicSenhaService, Senha } from "lib-smart-core";
import { sessionFilasQRCode } from '../../app.types';

@Component({
  selector: 'cadastro-component',
  styleUrls: ['./cadastro.component.css'],
  templateUrl: './cadastro.component.html'
})
export class CadastroComponent extends AbstractComponent implements OnInit {

  idFila: string;
  codigo: string;
  model: any;
  idEmpresa: string = null;
  empresa: Empresa;
  franquia: Franquia;
  isPermissaoWhatsApp;
  loading: boolean = false;

  fila: Fila;
  showCampos: boolean = false;
  campos: any[];
  preferencial: boolean;

  constructor(
    private senhaService: PublicSenhaService,
    private empresaService: PublicEmpresaService,
    private franquiaService: PublicFranquiaService,
    private filaService: PublicFilaService,
    private configService: PublicConfigService,
    injector: Injector,
  ) {
    super(injector);
    this.model = {};
  }

  ngOnInit() {
    this.fila = {} as Fila;
    this.idFila = super.getParam('idFila');
    this.preferencial = super.getParam("preferencial") === 'true';
    this.codigo = super.getParam('codigo');
    if (!!this.codigo) {
      this.loadFranquiaPorCodigo(this.codigo);
    }

    if (!!this.idFila) {
      this.getFilaById(this.idFila);
    }

    this.model.app = 'LITE';
    this.model.quantidade = 1;
    this.isPermissaoWhatsApp = true;
    this.model.permiteWhatsApp = false; // não enviar whatsapp direto no lite
  }

  onSubmit() {
    this.model.campos = [];
    if (!!this.showCampos && this.showCampos) {
      if (!!this.campos && this.campos.length > 0) {
        this.campos.forEach((campo: any, index: number) => {
          const newCampoValue = {
            key: campo.key,
            value: (document.getElementById('campo_' + index) as any).value
          }
          this.model.campos.push(newCampoValue);
        });
      }
    }

    const session = sessionStorage.getItem(sessionFilasQRCode);
    if (session) {
      this.model.permiteWhatsApp = true;
    }

    this.loading = true;
    this.senhaService.gerarNovaSenhaMetadata(this.idFila, this.model, this.preferencial).subscribe(
      (senha: Senha) => {
        this.loading = false;
        const session = sessionStorage.getItem(sessionFilasQRCode);
        if (session) {
          this.router.navigate(['confirmacao', this.franquia.codigo, 'senha', senha._id])
        } else {
          this.router.navigate(['painel', this.codigo, 'senha', senha._id]);
        }
      }, err => {
        this.loading = true;
        this.alertService.error(err.error.message)
      }
    );
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.idEmpresa = franquia.empresa._id;
        this.franquia = franquia;
        this.loadEmpresa();
        // this.loadConfig();
      }, err => this.alertService.error(err.error.message)
    );
  }

  getFilaById(id: string) {
    this.filaService.getById(id).subscribe(
      (fila: Fila) => {
        this.fila = fila;
        if (!!this.fila.showCampos && this.fila.showCampos) {
          this.showCampos = this.fila.showCampos;
          this.campos = this.fila.campos;
        }
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  setQuantidade(valor: number) {
    this.model.quantidade = valor;
  }

  subQuantidade() {
    this.model.quantidade <= 1 ? this.model.quantidade = 1 : this.model.quantidade--;
  }

  plusQuantidade() {
    this.model.quantidade++;
  }

  permissaoWhatsApp() {
    this.isPermissaoWhatsApp = !this.isPermissaoWhatsApp;
    this.model.permiteWhatsApp = this.isPermissaoWhatsApp;
  }

  existsCamposObrigatorios(): boolean {
    if (!!this.campos && this.campos.length > 0) {
      for (const campo of this.campos) {
        if (!!campo.required) {
          return true;
        }
      }
    }
    if (!!this.fila &&
      (!!this.fila.campoEmailRequired ||
      !!this.fila.campoPhoneRequired ||
      !!this.fila.campoNomeRequired)) {
      return true;
    }
    return false;
  }
}

