<div style="position: absolute; top: 0; left: 0; height: 10px; width: 100%; background-color: #0dd877;"></div>
<div class="flex justify-content-center align-items-center height-full-vh">
  <div class="width-full-vw">
    <section class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div style="display: flex; justify-content: center; align-items: end; height: 300px; margin: 3rem 0 0;">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="240" height="240" viewBox="0 0 64 64"
              style="fill:#0dd877;">
              <path
                d="M 32 10 C 19.85 10 10 19.85 10 32 C 10 44.15 19.85 54 32 54 C 44.15 54 54 44.15 54 32 C 54 19.85 44.15 10 32 10 z M 32 14 C 41.941 14 50 22.059 50 32 C 50 41.941 41.941 50 32 50 C 22.059 50 14 41.941 14 32 C 14 22.059 22.059 14 32 14 z M 40.962891 22.398438 C 40.320791 22.410684 39.696641 22.731797 39.322266 23.310547 L 29.613281 38.296875 L 24.503906 32.486328 C 23.774906 31.658328 22.508688 31.576641 21.679688 32.306641 C 20.850688 33.036641 20.769047 34.300859 21.498047 35.130859 L 28.353516 42.921875 C 28.735516 43.354875 29.283469 43.599609 29.855469 43.599609 C 29.903469 43.599609 29.952953 43.598703 30.001953 43.595703 C 30.626953 43.549703 31.192203 43.2125 31.533203 42.6875 L 42.679688 25.486328 C 43.279688 24.559328 43.014891 23.321703 42.087891 22.720703 C 41.739141 22.495703 41.34815 22.39109 40.962891 22.398438 z">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!franquia?.bloqueado">
        <div class="col-xs-12 col-md-12 text-center">
          <h1 style="font-size: 7rem; font-weight: 700; margin: 0 0 50px;">Sucesso!</h1>
          <p style="font-size: 4rem; margin-bottom: 50px;"><strong>{{senha?.nome ? senha?.nome : '' }}</strong>{{senha?.nome ? ', você entrou na fila.' : 'Você entrou na fila.'}}</p>
          <p style="font-size: 4rem; margin-bottom: 50px;">Senha: <strong>{{senha?.senha}}</strong></p>
          <p style="font-size: 2.7rem; margin-bottom: 5px; text-transform: uppercase; font-weight: 600;">Memorize para acompanhar no painel de TV.</p>
          <p style="font-size: 2.7rem; margin-bottom: 50px; text-transform: uppercase; font-weight: 600;"> Ou acompanhe pelo celular.</p>
          <button class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
            style="background-color: #0dd877; border-color: #0dd877; ; color: #fff;" (click)="done()">Concluir</button>
          <p style="margin: 5px;">{{ contador }} segundos...</p>
          <div class="row text-center">
            <h5>{{franquia?.nome}}</h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
