<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <logo-image
          *ngIf="empresa && franquia?._id"
          [empresa]="empresa"
          [idFranquia]="franquia?._id"
          [imgStyles]="'max-height: 40px'"
        ></logo-image>
      </a>
    </div>
    <div></div>
  </div>
</nav>
<div class="container mt20">
  <div class="row">
    <div class="col-md-12">
      <form (ngSubmit)="onSubmit()" #senhaForm="ngForm">

        <div class="form-group form-group-lg" *ngIf="fila.showNome">
          <div style="position: relative;">
            <input
              type="text"
              placeholder="Seu Nome"
              autocomplete="false"
              class="form-control input-lg form-lg-custom"
              name="nome"
              id="nome"
              #nome="ngModel"
              [required]="fila.showNome && fila.campoNomeRequired"
              [(ngModel)]="model.nome"
            />
            <div class="campos-obrigatorios" *ngIf="fila.campoNomeRequired">*</div>
          </div>
        </div>
        <div class="form-group form-group-lg" *ngIf="fila.showEmail">
          <div style="position: relative;">
            <input
              type="email"
              placeholder="Seu E-mail"
              autocomplete="false"
              class="form-control input-lg form-lg-custom"
              name="email"
              id="email"
              #email="ngModel"
              [required]="fila.showEmail && fila.campoEmailRequired"
              [(ngModel)]="model.email"
              email
            />
            <div class="campos-obrigatorios" *ngIf="fila.campoEmailRequired">*</div>
          </div>
        </div>
        <div class="form-group form-group-lg" *ngIf="fila.showPhone">
          <div style="position: relative;">
            <input
              type="tel"
              placeholder="Seu Telefone"
              autocomplete="false"
              class="form-control input-lg form-lg-custom"
              name="numero"
              id="numero"
              #numero="ngModel"
              mask="(00) 00000-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
              [required]="fila.showPhone && fila.campoPhoneRequired"
              [(ngModel)]="model.numero"
            />
            <div class="campos-obrigatorios" *ngIf="fila.campoPhoneRequired">*</div>
          </div>
        </div>

        <ng-container *ngIf="showCampos">
          <div
            class="form-group form-group-lg"
            *ngFor="let item of campos; let i = index"
          >
          <div style="position: relative;">
              <input
                type="text"
                id="campo_{{ i }}"
                name="campo_{{ i }}"
                autocomplete="false"
                placeholder="{{ item.key }}"
                class="form-control input-lg form-lg-custom"
                [required]="item.required"
                [(ngModel)]="model[item.key]"
              />
              <div class="campos-obrigatorios" *ngIf="item.required">*</div>
            </div>
          </div>
        </ng-container>

        <div class="form-group form-group-lg">
          <label style="font-size: 16px">Termo de consentimento</label>
          <div class="b-r-lg p-xs border-left-right border-top-bottom">
            <p style="font-size: 2rem">
              Estou ciente de que, ao criar esta senha, a instituição poderá
              utilizar meus dados para entrar em contato comigo, caso seja
              necessário.
            </p>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="permiteCadastro"
                  id="permiteCadastro"
                  [required]="true"
                  [(ngModel)]="model.permiteCadastro"
                />
                Aceitar o termo
              </label>
            </div>
          </div>
        </div>

        <h5 class="text-center">{{ franquia?.nome }}</h5>
        <div
        *ngIf="existsCamposObrigatorios()"
        class="alert alert-warning">
          Campos Obrigatórios *
        </div>

        <div
          style="
            position: fixed;
            width: 100%;
            padding: 0 15px 15px;
            bottom: 0;
            left: 0px;
          "
        >
          <button
            class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
            [ngStyle]="{
              color: empresa?.textColor,
              'background-color': empresa?.bgColor,
              'border-color': empresa?.bgColor
            }"
            [disabled]="loading || !senhaForm.valid"
            type="submit"
          >
            Continuar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
