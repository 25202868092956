<div class="flex justify-content-center height-full-vh {{filas.length > 10 ? 'align-items-start' : 'align-items-center'}}"
  style="overflow: scroll;">
  <div class="width-full-vw">
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
            [imgClass]="'tablet-windows'" style="display: flex; justify-content: center;"></logo-image>
        </div>
      </div>
    </div>
    <div class="row text-center" *ngIf="!franquia?.bloqueado">
      <h3 class="tablet-windows_texto">Selecione uma fila</h3>
      <div *ngFor="let fila of filas">
        <div class="col-xs-12 col-md-8 col-md-offset-2" *ngIf="fila.visivelTablet">

          <button *ngIf="fila.preferencial"
            class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md" data-toggle="modal"
            href="#modalNormalPreferencial"
            [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
            (click)="selecionar(fila)">{{fila.nome | uppercase}}</button>

          <button *ngIf="!fila.preferencial" [disabled]="loading"
            class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
            [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
            (click)="gerarNovaSenha(fila)">{{fila.nome | uppercase}}</button>

          <br />
        </div>
      </div>
    </div>
    <div class="row text-center">
      <h5>{{franquia?.nome}}</h5>
    </div>
  </section>


  <div class="modal inmodal" id="modalNormalPreferencial" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" #btnCloseSelectNormalPreferencial>
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Selecione a opção</h4>
        </div>
        <div class="modal-body">
          <div align="center">
            <button data-dismiss="modal" [disabled]="loading"
              class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
              [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
              (click)="gerarNovaSenha(filaSelecionada, false)">NORMAL</button>
            <button data-dismiss="modal" [disabled]="loading"
              class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
              [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
              (click)="gerarNovaSenha(filaSelecionada, true)">PREFERENCIAL</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
